#sidebar {
  overflow: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#sidebar .pro-sidebar-inner .pro-sidebar-layout::-webkit-scrollbar {
  display: none;
}

/* #sidebar .pro-sidebar.collapsed {
  width: 120px;
  min-width: 80px;
} */

#sidebar .pro-sidebar-inner .pro-sidebar-layout {
  background-color: var(--primary);
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout .active {
  background-image: linear-gradient(0deg, var(--white) 0%, var(--primary) 100%);
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: var(--white);
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: transparent;
}
